export default class User {
  constructor(mobile, name, family, token, type, creationTime) {
    this.mobile = mobile
    this.name = name
    this.family = family
    this.token = token
    this.type = type
    this.creationTime = creationTime
  }
}
