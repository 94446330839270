<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="user.avatar"
            :text="avatarText(user.name)+avatarText(user.family)"
            variant="light-primary"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ user.name + ' ' + user.family }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                  @click="showModal"
                variant="primary"
              >
                ویرایش
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                حذف
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-primary"
              rounded
            >
              <feather-icon
                icon="ClockIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ new Date(user.creationTime * 1000).toLocaleDateString('fa-IR') }}
              </h5>
              <small>تاریخ ثبت نام</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="ActivityIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                فعال
              </h5>
              <small>وضعیت اکانت</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">نام</span>
            </th>
            <td class="pb-50">
              {{ user.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">نام خانوادگی</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ user.family }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">نوع کاربری</span>
            </th>
            <td class="pb-50 text-capitalize">
              عادی
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bolder">شماره همراه</span>
            </th>
            <td>
              {{ user.mobile }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <!-- modal -->
    <b-modal
      ref="modal-editUser"
      cancel-variant="outline-secondary"
      ok-title="افزودن"
      cancel-title="بستن"
      centered
      title="ویرایش اطلاعات"
    >
      <b-form>
        <b-form-group
          label="نام:"
          label-for="vi-name"
        >
          <b-form-input
            id="vi-name"
            v-model="user.name"
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          label="نام خانوادگی:"
          label-for="vi-family"
        >
          <b-form-input
            id="vi-family"
            v-model="user.family"
            placeholder=""
          />
        </b-form-group>
      </b-form>

      <template #modal-footer="">
        <b-button
          size="sm"
          variant="danger"
          @click="hideModal"
        >
          بستن
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="modalOk()"
        >
          ذخیره
        </b-button>
      </template>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import User from '@/models/user'

import userService from '@/services/user.service'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BModal, BForm, BFormInput, BFormGroup,
  },
  async mounted() {
    this.initialize()
  },

  data() {
    return {
      // eslint-disable-next-line no-underscore-dangle
      userId: getUserData()._id,
      user: new User('', '', '', '', '', ''),
    }
  },
  setup() {
    return {

      avatarText,
    }
  },
  methods: {
    initialize() {
      userService.getInfo().then(
        res => {
          if (res.status === 'success') {
            if ('result' in res && 'data' in res.result) {
              // eslint-disable-next-line no-plusplus
              this.user = res.result.data
            }
          }
        },
        error => {
          this.message = (error.response && error.response.data)
                || error.message
                || error.toString()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'register error',
              text: this.message,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        },
      )
    },
    async modalOk() {
      await userService.update(this.user)
        .then(
          res => {
            if (res.status === 'success') {
              this.$refs['modal-editUser'].hide()
              const newUserData = getUserData()
              newUserData.name = this.user.name
              newUserData.family = this.user.family
              localStorage.setItem('user', JSON.stringify(newUserData))
              this.$forceUpdate()
            } else {
              this.message = res.message
            }
          },
          error => {
            this.message = (error.response && error.response.data)
                    || error.message
                    || error.toString()
          },
        )
      if (this.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'register error',
            text: this.message,
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    showModal() {
      this.$refs['modal-editUser'].show()
    },
    hideModal() {
      this.$refs['modal-editUser'].hide()
    },
  },
}
</script>

<style>

</style>
